import * as React from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import { Button } from "@soundtrackyourbrand/ui/src/Button";
import InputType from "../../components/blocks/Form/FormElements/InputType";
import * as FormStyling from "../../components/blocks/Form/Form.module.scss";
import * as AffiliateStyle from "./affiliate.module.scss";
import Recaptcha from "components/Recaptcha/recaptcha.js";
import { createTrackingEvent } from "lib/tracking";
import api from "../../lib/api";
import cn from 'classnames'

const Affiliate = ({ pageContext }) => {
  const [state, setState] = React.useState({
    loading: false,
    username: null,
  })
  const recaptchaRef = React.createRef();
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true);
  const countries = JSON.parse(pageContext.countries[0].countries).options
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const companyTypes = [
    "AV Integrator",
    "Music Curator",
    "IT Consultant",
    "Experience agency",
    "Marketing affiliate",
    "Hardware distributor",
    "Other"
  ]

  React.useEffect(() => {
    createTrackingEvent('Storefront - View Affiliate Signup', {})
  }, [])

  const onSubmit = (data) => {
    api.signUpAffiliate(data).then((response) => {
      if (response.ok) {
        // Success
        createTrackingEvent('Storefront - Affiliate form submitted', {})
        setState({ ...state, username: data.email, loading: false });
        reset();
        navigate("/partner-get-started");
      } else {
        // Error
        createTrackingEvent('Storefront - Affiliate form error', { response: response })
        alert(`Error: ${response.status}`)
      }
    })
  }

  return (
    <Section className={AffiliateStyle.wrapper}>
      <h1 className="mb-8">Sign up to become a Soundtrack Partner</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <p className="text5">Company details</p>
          <div className="row">
            <InputType
              label={"Company name"}
              name={"company"}
              register={register}
              errors={errors}
              isRequired={true}
              className="col m-6"
            />
            <div className="field is-valid col m-6">
              <label className="field-input">
                <select
                  {...register("custom3", { required: true })}
                  className={cn(FormStyling.formSelect, errors['custom2'] && FormStyling.invalid)}
                >
                  <option value=""> </option>
                  {companyTypes.map((option) => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <label
                  htmlFor="custom3"
                  className={`field-label ${FormStyling.fieldSelectLabel}`}>
                  Company type<span className="primary-color"> *</span>
                </label>
              </label>
            </div>
            <InputType
              label={"VAT / Tax ID"}
              name={"taxId"}
              register={register}
              errors={errors}
              isRequired={false}
              className="col m-6"
              darkText={true}
              description="Optional. Used for payouts and can be provided later"
            />
            <InputType
              label={"Paypal address"}
              name={"receptionIdentifier"}
              register={register}
              errors={errors}
              isRequired={false}
              className="col m-6"
              darkText={true}
              description="Optional. Used for payouts and can be provided later"
            />
          </div>
        </div>
        <div className="mb-6">
          <p className="text5">Address</p>
          <div className="row">
            <InputType
              label={"City"}
              name={"city"}
              register={register}
              errors={errors}
              isRequired={true}
              className="col m-6"
            />
            <InputType
              label={"Zip/Postal Code"}
              name={"zipPostalCode"}
              register={register}
              errors={errors}
              isRequired={true}
              className="col m-6"
            />
            <div className="field is-valid col m-6">
              <label className="field-input">
                <select
                  {...register("countryCode", { required: true })}
                  className={cn(FormStyling.formSelect, errors['countryCode'] && FormStyling.invalid)}
                >
                  <option value=""> </option>
                  {countries?.map((option) => {
                    const value = option.code === 'GB' ? 'UK' : option.code
                    return (
                      <option key={value} value={value}>
                        {option.name}
                      </option>
                    )
                  })}
                </select>
                <label
                  htmlFor="countryCode"
                  className={cn('field-label', FormStyling.fieldSelectLabel)}>
                  Country<span className="primary-color"> *</span>
                </label>
              </label>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <p className="text5">Contact person</p>
          <div className="row">
            <InputType
              label={"First Name"}
              name={"firstName"}
              register={register}
              errors={errors}
              isRequired={true}
              className="col m-6"
            />
            <InputType
              label={"Last Name"}
              name={"lastName"}
              register={register}
              errors={errors}
              isRequired={true}
              className="col m-6"
            />
            <InputType
              label={"Email"}
              name={"email"}
              register={register}
              errors={errors}
              isRequired={true}
              className="col m-6"
            />
          </div>
        </div>
        <div>
          <p className="text3">
            Find our General Terms and Conditions & Privacy Policy, and more on our legal pages <a href="/legal" target="_blank">legal pages</a>
          </p>
        </div>
        <div className="field is-valid text4">
          <label className="inline-label">
            <input
              type="checkbox"
              {...register("terms", { required: true })}
            />
            <span>
              I accept Terms & Conditions & Privacy Policy.
            </span>
          </label>
          {errors.terms && (
            <span className="error field-message" role="alert">
              Must be checked
            </span>
          )}
        </div>
        <div className="field">
          <Recaptcha
            recaptchaRef={recaptchaRef}
            onChange={() => {
              setSubmitButtonDisabled(false);
            }}
          />
        </div>
        <Button
          disabled={submitButtonDisabled}
          primary
          type="submit"
          children="submit application"
          loading={state.loading}
        />
      </form>
    </Section>
  );
};

export default Affiliate;