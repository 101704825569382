import React from 'react';
import * as FormStyling from "../Form.module.scss";

const InputType = ({
  label,
  name,
  description,
  isRequired,
  register,
  errors,
  darkText,
  ...props
}) => {
  return (
    <div className={`field ${props.className}`}>
      <label className="field-input">
        <input
          placeholder=" "
          className={
            errors[name]
              ? `${FormStyling.formInput} ${FormStyling.invalid}`
              : `${FormStyling.formInput}`
          }
          type={label}
          {...register(name, { required: isRequired })}
        />
        <label className={`${FormStyling.fieldInputLabel} field-label`}>
          {label}
          {isRequired && <span className="primary-color"> *</span>}
        </label>
      </label>
      {description && (
        <span className={`col field-message ${darkText ? "" : FormStyling.aboutMessage}`}>
          {description}
        </span>
      )}
    </div>
  );
};

export default InputType;
